import React, {useEffect} from 'react';
import Container from "../Container/Container";
import {Field, reduxForm} from "redux-form";
import Input from "../Form/Input";
import {email, required} from "../../helpers/validation/fieldvalidation";
import {isGranted, roles} from "../../helpers/roles";
import OfficeSelect from "../Form/OfficeSelect";
import RoleSelect from "../Form/RoleSelect";
import Spinner from "../Spinner/Spinner";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {toastr} from "react-redux-toastr";
import {register} from "../../redux/thunks/authThunks";

const NewUser = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useSelector(state => state.auth.user);
  const { isLoading } = useSelector(state => state.users);

  useEffect(() => {
    if (!isGranted(roles.ADMIN, authUser.roles)) {
      navigate('/');
    }
  }, [authUser.roles, navigate]);

  const generatePassword = () => {
    const length = 12;
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }

    return retVal;
  }

  const handleFormSubmit = formValues => {
    const password = generatePassword();
    formValues = {...formValues, password: password, passwordConfirm: password};
    dispatch(register(formValues))
      .then(unwrapResult)
      .then(() => {
        toastr.success(
          'Horrai!',
          'Utilisateur ajouté avec succès.'
        );

         navigate('/users');
      })
      .catch(e => {
        toastr.error('Oups!', e.violations[0].message);
      });
  };

  return (
    <Container>
      <form className="form__form">
        <div className="form__title">Nouvel utilisateur</div>
        <div className="form__two-columns">
          <Field
            name="firstName"
            type="text"
            placeholder="Prénom"
            label="Prénom"
            component={Input}
            validate={[required]}
          />
          <Field
            name="lastName"
            type="text"
            placeholder="Nom"
            label="Nom"
            component={Input}
            validate={[required]}
          />
        </div>
        <Field
          name="username"
          type="email"
          placeholder="Email"
          label="Email"
          component={Input}
          validate={[required, email]}
        />
        <Field
          name="job"
          type="text"
          placeholder="Poste"
          label="Poste"
          component={Input}
          validate={[required]}
        />
        <Field
          name="company"
          type="text"
          placeholder="Entreprise"
          label="Entreprise"
          component={Input}
          validate={[required]}
        />
        <Field name="office"
             component={OfficeSelect}
             validate={required}
        />
        <div>
          <label htmlFor="office" className="form__label">
            Rôle
          </label>
          <Field name="role"
               component={RoleSelect}
               validate={[required]}
          />
        </div>
        <div className="form__button-wrapper">
          <button
            disabled={isLoading}
            className={`form__button ${isLoading ? 'form__button--disabled' : ''
            }`}
            onClick={handleSubmit(handleFormSubmit)}
            type={'submit'}
          >
            Ajouter
          </button>
          <Spinner loading={isLoading} overlay />
        </div>
      </form>
    </Container>
  )
};

export default reduxForm({
  form: 'userDetailsForm'
})(NewUser);
